import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import { Link, useStaticQuery, graphql } from "gatsby"

import campaign from "../images/campaign.svg"

import soru from "../images/soru.svg"
import { RiArrowRightSLine } from 'react-icons/ri';

import Image from "gatsby-image"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import '../styles/grid.scss'
import '../styles/index.scss'


const CampaignPage = () => {
  
  const data = useStaticQuery(graphql`
    query {
        allCampaignsJson {
          edges {
            node {
              name
              category
              discountAmount
              discountType
              image{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
          }
    }
  `)

  return(
    <Layout>
      <Head title="Avantajlar" description="Çember’de biriktirdiğin paraları ne için harcamak istersin? Kullanıcılara özel avantajlar için aşağıdaki markalara göz at." />
      <div className="row campaignHero">
       <div className="hero-container">
        <div className="container">
          <div className="row">
         
            <div className="col-6">
              <div className="hero-text">
                <h1>Avantajlar <span role="img" aria-label="Emoji"> 💸 </span> </h1>
                <p>Çember’de biriktirdiğin parayla hangi hayalini gerçekleştirmek istiyorsun? Kullanıcılara özel avantajlar için aşağıdaki markalara göz at. </p>  
                <p>İstediğin markayı göremedin mi? Bize yazabilirsin.</p>  
                 <h5>
                   <Link className="cta-button" to="/contact">Bize Yaz</Link>
                </h5>        
              </div>
            </div>
            <div className="col-6 center">
              <img src={campaign} alt="Avantajlar"/> 
            </div>
            
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
        <div className="container campaigns">
          <div className="row section-title">
            <h1>Tüm Avantajlar <span role="img" aria-label="Emoji"> ✨ </span> </h1>
          </div>
          <div className="row">
              <ul>
              {data.allCampaignsJson.edges.map((brandCampaign)=>{
                console.log(brandCampaign)
                
                return(
                  <li>
                    <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "campaign",
                      action: "click",
                      label: brandCampaign.node.link
                    })
                  }}  rel="noreferrer" target="_blank" href={brandCampaign.node.link}>
                    
                    <Image link={brandCampaign.node.link}
                        fluid={brandCampaign.node.image.childImageSharp.fluid}
                        alt={brandCampaign.node.name}
                      />
                      
                    </a>

                    
                    
                  </li>
                
                )
              })}
              </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="col-12">
            <div className="sorukutu">
              <h1>Aklında başka sorular varsa bize ulaş!</h1>
              <Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Bize yaz </span> </Link>
              <img src={soru} alt="Soru Sor"/>
            </div>
          </div>
        </div>
    </div>

    </Layout>
  )
  
}


export default CampaignPage